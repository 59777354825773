.footer__main {
  margin-top: $spacing-xxl;
  border-top: 1px solid $bordergray;
  font-size: $font-size-sm;

  ul {
    @extend .wrapper;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: $spacing-sm 0;
  }

  li {
    list-style-type: none;
  }
}