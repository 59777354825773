table.dt-rowReorder-float {
  position: absolute !important;
  opacity: 0.5;
  table-layout: fixed;
  z-index: 2001;
  max-height: 40px;

  td {
    cursor: grabbing !important;
  }

  .text-content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

tr.dt-rowReorder-moving {
  outline: 1px solid $blue;
  outline-offset: -1px;
  max-height: 32px;
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}

// table.dataTable td.reorder {
//     text-align: center;
//     cursor: move;
// }


.table-reorderable>tbody {
  &>tr>td:last-child {
    position: relative;
    padding-right: 0 !important;

    .reorder {
      padding: 0 calc(#{$grid-gutter-width} /2);
      cursor: grab;
      color: $gray;
      opacity: 0.3;
      transition: 0.2;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &>tr>td.dataTables_empty {
    padding-right: calc(#{$grid-gutter-width} /2) !important;
  }

}

.datatable-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  font-size: 2.5rem;
  font-weight: bold;
  background-color: #efefef;
}

.dataTables_length select {
  border: none;
  border-radius: 0;
  padding-top: 2px;
  padding-bottom: 2px;
}