.CodeMirror,
.editor-toolbar,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default .select2-selection--multiple,
.select2-selection,
.select2-dropdown,
.form-control {
  border-width: $border-width-sm !important;
  border-color: $bordergray !important;
  border-style: solid;
  border-radius: 0px !important;
}




.editor-toolbar {
  border-bottom: none;

  &::after {
    margin-top: $spacing-xxs;
  }

  &::before {
    margin-bottom: $spacing-xxs;
  }

  &,
  &:hover {
    opacity: 1;
  }

  button {
    color: black;
    border: none !important;

    &:hover,
    &.active {
      background-color: $lightgray;
      color: black;
    }
  }
}

.editor-statusbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $spacing-xxs 0;

  @include media('>=sm') {
    flex-direction: row;
  }

  span {
    display: inline-block;
    min-width: auto;
    margin-left: 0;
  }

  .upload-image,
  .lines {
    margin-right: $spacing-sm;
    margin-top: $spacing-xxs;

    @include media('>=sm') {
      margin-top: 0;
    }
  }

  .lines,
  .words {
    white-space: nowrap;
  }

  .autosave,
  .cursor {
    display: none;
  }

  .upload-image {
    flex-grow: 1;
    text-align: left;
  }
}