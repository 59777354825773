.preview-tool {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(0);
  z-index: 999;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 $spacing-md rgba(0, 0, 0, .1);
  background: #fff;
  transition: .25s ease-out;
  transition-property: transform, opacity;

  &.active {
    pointer-events: all;
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }

  &__close {
    line-height: 1;
    cursor: pointer;
    color: $white;
    background: $blue;
    padding: $spacing-sm calc(#{$grid-gutter-width} / 1.5);
  }

  &__iframe-container {
    background: $lightgray;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
  }

  &__iframe-resizer {
    cursor: ew-resize;
    width: 10px;
    height: 100%;
    background: repeating-linear-gradient(to right, #000, transparent 1px, transparent 2px, #fff 2px);
    border-left: 1px solid $lightgray;
  }

  &__iframe {
    width: 100%;
    height: 100%;
    transition: .25s ease-out;
    transition-property: width, height;
  }

  .header {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;

    &__col {
      &--left {
        display: flex;
        flex: 1;
      }
    }
  }

  .tools {
    padding: $spacing-sm $grid-gutter-width;
    display: flex;
    align-items: center;

    &__item {
      line-height: 1;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: calc(#{$grid-gutter-width}/1.5);
      }

      &.active {
        color: $blue;
      }
    }
  }
}
