.article-aside {
  vertical-align: top;

  @include media(">=sm") {
    width: calc(33% - #{$grid-gutter-width});
    display: inline-block;
    margin-left: $grid-gutter-width;
  }

  @include media("<sm") {
    margin: $spacing-sm 0;
  }

  .panel:not(:first-child) {
    margin-top: $spacing-lg;
  }

  .panel-action {
    padding-top: 0;
  }
}

.input-media-upload {
  display: none;
}

.media-panel {
  .message {
    position: absolute;
    top: $spacing-sm;
    left: 50%;
  }
}


.panel {
  list-style: none;

  .list__item {
    @extend %font-size-sm;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      padding-bottom: $spacing-sm;
      border-bottom: $border-width-sm solid $lightgray;
    }

    &:not(:first-child) {
      padding-top: $spacing-sm;
    }
  }

  .item__infos {
    margin-right: $spacing-xs;
    width: calc(100% - 100px);
    justify-content: space-between;
    flex-direction: column;
  }

  .item__title,
  .media__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item__actions {
    color: $gray;

    form {
      display: inline;
    }
  }

  .media__preview {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    display: flex;

    &.txt {
      background-color: $lightyellow;
    }

    span {
      @extend %font-size-lg;
      margin: auto;
      text-transform: uppercase;
      color: $gray;
    }
  }
}