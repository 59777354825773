.panel {
  position: relative;
}

.panel-heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // padding-top: $spacing-xs;
  padding-bottom: 0;

  &,
  input {
    @extend .ts-small;
  }

  .tab-select {
    @extend .ts-subtitle;
  }

  h2 {
    @extend .ts-subtitle;
    padding: $spacing-xs 0;
  }

  .btn-group {
    display: flex;

    .btn:not(:first-child) {
      margin-left: 1px;
    }

    .btn:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: calc(#{$grid-gutter-width} / 3);
    }

    .btn:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: calc(#{$grid-gutter-width} / 3);
    }

    .btn:not(:first-child):not(:last-child) {
      border-radius: 0;
      padding-left: calc(#{$grid-gutter-width} / 3);
      padding-right: calc(#{$grid-gutter-width} / 3);
    }
  }
}

.panel-body {
  h3:not(:first-child) {
    margin-top: $spacing-md;
    margin-bottom: $spacing-sm;
  }

  label {
    color: $base-color;
    @extend .ts-label;
  }

  padding-left :calc(#{$grid-gutter-width} / 2);
  padding-right :calc(#{$grid-gutter-width} / 2);
  padding-top: $spacing-sm;
  padding-bottom: $spacing-sm;
  background-color: $white;

  &--medias {
    padding-left: 0;
  }
}

.panel-footer {
  @extend .ts-small;

  li {
    margin-right: 0;
    display: inline-block;

    &:not(:first-child)::before {
      content: '/';
      margin-right: $spacing-xxs;
    }
  }

  list-style: none;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-md;
  color: $gray;
}

.panel-action {
  position: sticky;
  bottom: 0;
  border-top: $border-width-md solid $lightgray;
  margin-top: $spacing-sm;
}