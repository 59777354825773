.tab-pane {
  display: none;

  &.active {
    display: block;
  }
}

.tab-select {
  @extend %font-size-sm;
  list-style: none;
  margin-bottom: -1px;

  li {
    display: inline-block;
    text-align: center;
    text-transform: capitalize;
    outline: $border-width-sm solid $body-color;
    outline-offset: -1px;

    a {
      display: inline-block;
      padding: $spacing-xs $spacing-sm;
      @extend .ts-link;
    }

    &.active {
      background-color: $body-color;

      a {
        color: $base-color;
      }
    }
  }
}