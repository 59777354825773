.navbar__nav,
.navbar__list {
  display: flex;
}

.navbar {
  z-index: 2;
  background-color: $body-color;
  width: 100%;
  overflow-x: auto;
  height: auto;
  border-bottom: $border-width-sm solid $lightgray;

  &.navbar--fixed-top {
    position: sticky;
    top: 0;
  }

  a {
    color: $black;
    text-decoration: none;

    &:hover {
      color: $action-response;
      text-decoration: none;
    }

    line-height: 1;
    display: block;
    @extend %font-size-md;
  }

  &.nav--secondary {
    list-style: none;
    width: 100%;
    background: $body-color;
    position: sticky;
    display: none;
    top: 45px;
    border-top: 1px solid $bordergray;
    margin-top: -1px;

    @include media('>=sm') {
      top: 52px;
    }

    .js-dropdown-menu {
      display: flex;
      @extend .wrapper;

      @include media(">=sm") {
        justify-content: flex-end;

        li:last-of-type a {
          padding-right: 0;
        }
      }
    }

    li {
      flex-shrink: 0;
    }

    a {
      color: $gray;
      padding-left: calc(#{$grid-gutter-width} / 3);
      padding-right: calc(#{$grid-gutter-width} / 3);
      padding-top: $spacing-sm;
      padding-bottom: $spacing-sm;

      &:hover,
      &.active {
        color: $action-response;
      }
    }
  }
}

.navbar__brand {
  margin-right: calc(30px + #{$spacing-sm});
  position: relative;
  display: block;
  top: 10px;

  @include media('>=sm') {
    top: 12px;
  }

  @extend %fantom-small;
  background-repeat: no-repeat;
  background-position: center;
}

.navbar__nav {
  @extend .wrapper;

  &:hover {
    .navbar__brand {
      @extend %fantom-small-uh;
    }
  }
}

.navbar__list {
  align-items: center;

  &--left {
    padding-right: $spacing-sm;
    flex: 1;

    .active a {
      color: $blue;
    }
  }

  &--right {
    li:last-child {
      a {
        padding-right: 0;
      }
    }
  }

  &,
  & li {
    flex-shrink: 0;
    list-style: none;
  }

  li {
    &:first-of-type a {
      padding-left: 0;
    }
  }

  a {
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
    padding-left: calc(#{$grid-gutter-width} / 3);
    padding-right: calc(#{$grid-gutter-width} / 3);
  }
}

.lang__link {
  text-transform: capitalize;
}