.edit__header {
  margin-bottom: $spacing-md;
}

.edit__title {
  display: block;
  @extend .ts-title;
}

.panel {
  &.panel-edit {
    @include media(">=sm") {
      width: 66%;
      display: inline-block;
    }

    .panel-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0;

      .tab-select {
        flex-shrink: 0;
      }
    }

    &.panel-edit--single {
      display: block;
      margin: 0 auto;

      // .panel-heading {
      //   padding-bottom: $spacing-xs; // test
      // }
    }
  }

  .panel-body+.panel-body {
    margin-top: $spacing-sm;
  }

  .edit-details {
    text-align: right;

    >* {
      display: inline-block;

      &:not(:first-child) {
        margin-left: $spacing-xs;
      }
    }

    input {
      line-height: 1;
    }
  }
}

.created_at input {
  border: none;
  padding: 0;
  text-align: right;
  background-color: transparent;
  color: $gray;

  &:focus {
    color: $black;
  }
}

.edit-details .is-published,
.form-input.checkbox {
  label {
    cursor: pointer;
  }

  input[type="checkbox"] {
    display: none;
  }

  span::before {
    @extend .icon-published;
    content: "\f10c";
    font-family: 'FontAwesome';
  }

  :checked+span::before {
    content: "\f111";
  }
}

 .is-published.featured span::before{
   color: $red;
 }

.form-group {
  .slug {
    @extend .ts-small;
    display: inline-block;
    color: $gray;
    margin-top: $spacing-xxs;
    margin-bottom: $spacing-xs;
  }
}
